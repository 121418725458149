import { HeaderAuth, InputPassword } from 'src/components'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import styles from './renew-credentials.module.scss'
import { useUserSettingsUseCases } from 'src/service-locator'
import { useParams } from 'react-router-dom'

import {
  Typography,
  Button,
  TextField,
  Anchor,
  InputLabel,
} from '@prometeoapi/afrodita-react-component-lib'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import { WarningAlert } from 'src/presentation'
import { useState } from 'react'
import { Alert, AlertBody } from '@lib-components'

export const RenewCredentialsPage = () => {
  const { t } = useTranslation(['register', 'shared', 'auth'])
  const { token } = useParams()
  const [errorMessage, setErrorMessage] = useState('')
  const { updateExpiredPassword } = useUserSettingsUseCases()
  const [updateSuccess, setUpdateSuccess] = useState(false)

  const formSchema = Yup.object({
    token: Yup.string(),
    password: Yup.string()
      .required(
        t('shared:form.errors.requiredField', {
          name: t(`register:registerPage.password`),
        })
      )
      .min(
        8,
        t('auth:error.length', {
          name: t(`register:registerPage.password`),
        })
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
        t('auth:error.regex', {
          name: t(`register:registerPage.password`),
        })
      ),
    confirmPassword: Yup.string()
      .required(
        t('shared:form.errors.requiredField', {
          name: t(`register:registerPage.confirmPassword`),
        })
      )
      .min(
        8,
        t('auth:error.length', {
          name: t(`register:registerPage.confirmPassword`),
        })
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
        t('auth:error.regex', {
          name: t(`register:registerPage.confirmPassword`),
        })
      )
      .oneOf(
        [Yup.ref('password')],
        t('resetpage:resetPasswordConfirm.error.match', {
          name: t(`register:registerPage.confirmPassword`),
        })
      ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      token: token,
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(formSchema),
  })

  const updatePasswordAction = async (data: any) => {
    const payload = {
      token: data.token,
      password: data.password,
    }
    try {
      await updateExpiredPassword(payload)
      setUpdateSuccess(true)
      reset()
    } catch (error: any) {
      setErrorMessage(error._messageForUser.description)
      return error
    }
  }

  const onSubmit: SubmitHandler<any> = (data) => {
    updatePasswordAction(data)
  }

  return (
    <div>
      <HeaderAuth />
      <div className={styles['main-container']}>
        <main className={styles['main']}>
          <div className={styles['body-card']}>
            {updateSuccess ? (
              <Alert variant={'success'}>
                <AlertBody>{t('auth:successUpdateExpiredPassword')}</AlertBody>
              </Alert>
            ) : (
              <WarningAlert>{t('auth:alert')}</WarningAlert>
            )}
            <Typography variant={'display-3'}>
              {t('auth:renewCredential')}
            </Typography>

            <div className={styles['form-section']}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <div className={styles['input input-group']}>
                        <InputLabel htmlFor="some-input-id" id="input-label">
                          <Typography
                            variant={'body-2'}
                            typographyColor="gray-6"
                          >
                            {t('register:registerPage.password')}
                          </Typography>
                        </InputLabel>
                        <InputPassword
                          {...field}
                          type="password"
                          error={errors?.password?.message}
                        />
                      </div>
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                      <div className={styles['input input-group']}>
                        <InputLabel htmlFor="some-input-id" id="input-label">
                          <Typography
                            variant={'body-2'}
                            typographyColor="gray-6"
                          >
                            {t('register:registerPage.confirmPassword')}
                          </Typography>
                        </InputLabel>
                        <InputPassword
                          {...field}
                          type="password"
                          error={errors?.confirmPassword?.message}
                        />
                      </div>
                    )}
                  />
                </div>
                <div className={styles['token']}>
                  <Controller
                    name="token"
                    control={control}
                    render={({ field }) => (
                      <TextField type="hidden" {...field} />
                    )}
                  />
                </div>
                {errorMessage ? (
                  <p className={styles['error-text']}>{errorMessage} </p>
                ) : (
                  ''
                )}
                <div className={styles['button-container']}>
                  <Button
                    type={'submit'}
                    fullWidth
                    className={styles['btn-submit']}
                  >
                    {t('auth:buttonUpdtae')}{' '}
                  </Button>
                </div>
              </form>
            </div>

            <div className={styles['forgot-password']}>
              <Typography variant={'body-1'} tag={'span'}>
                {t('auth:forgotPassword')}{' '}
              </Typography>
              <Anchor href={'/reset-password'}>
                {t('auth:recoverPassword')}
              </Anchor>
            </div>
          </div>
          <div className={styles['footer-card']}>
            <div>
              <Typography variant={'body-1'} typographyColor="gray-4">
                {t('auth:haveAccount')}
              </Typography>
              <Anchor href={'/register-account'}>
                {' '}
                {t('auth:authSection.registerLink')}
              </Anchor>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
