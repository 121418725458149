import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  // Button,
  Typography,
  Spacer,
  Anchor,
  Alert,
  AlertTitle,
  AlertBody,
  InputLabel,
} from '@lib-components'
import { ButtonIconMoon } from '@prometeoapi/afrodita-react-component-lib'

import { LoginArgs, User } from 'src/domain'
import { APIError } from 'src/adapters'
import { useAuth } from 'src/infrastructure'

import styles from './login-form.module.scss'
import { HeaderAuth, InputPassword, InputText } from 'src/components'
import { useLocation, useNavigate } from 'react-router-dom'

const LoginFormPage: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const from = location?.state?.from?.pathname || '/'
  const { t } = useTranslation(['shared', 'auth'])
  const { login, setUser } = useAuth()

  const mutation = useMutation<User, APIError, LoginArgs>(login, {
    onSuccess: (data) => {
      setUser(data)
      navigate(from, { replace: true })
    },
    onError: () => {
      setShowAlert(true)
    },
  })
  const [showAlert, setShowAlert] = useState<boolean>(false)

  const handleAlertClose = () => {
    setShowAlert(false)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('shared:form.errors.emailField', { name: 'Email' }))
        .required(t('shared:form.errors.requiredField', { name: 'Email' })),
      password: Yup.string().required(
        t('shared:form.errors.requiredField', { name: 'Password' })
      ),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        await mutation.mutateAsync(values)
      } catch (error: any) {
        const response = error.response.data
        const errorExpected = { message: 'Expired password' }
        if (response.message === errorExpected.message) {
          navigate(`/renew-credentials/${response.token}`)
        }
      }
    },
  })

  return (
    <div>
      <HeaderAuth />
      <div className={styles['main-container']}>
        <main className={styles['main']}>
          <div className={styles['body-card']}>
            <Typography variant={'display-3'}>{t('auth:logIn')}</Typography>
            {mutation.isError && showAlert && (
              <>
                <Spacer variant={'vertical'} size={'8x'} />

                <Alert variant={'danger'} onClose={handleAlertClose}>
                  {mutation.error.messageForUser?.label && (
                    <AlertTitle>
                      {mutation.error.messageForUser.label}
                    </AlertTitle>
                  )}
                  <AlertBody>
                    {mutation.error.messageForUser.description}
                  </AlertBody>
                </Alert>
              </>
            )}
            <Spacer variant={'vertical'} size={'8x'} />

            <form onSubmit={formik.handleSubmit}>
              <div className={styles['input input-group']}>
                <InputLabel htmlFor="some-input-id" id="input-label">
                  <Typography variant={'body-2'} typographyColor="gray-6">
                    {t('auth:form.fields.email.placeholder')}
                  </Typography>
                </InputLabel>
                <InputText
                  {...formik.getFieldProps('email')}
                  type="email"
                  error={formik?.errors?.email}
                />
              </div>

              <div className={styles['input input-group']}>
                <InputLabel htmlFor="some-input-id" id="input-label">
                  <Typography variant={'body-2'} typographyColor="gray-6">
                    {t('auth:form.fields.password.placeholder')}
                  </Typography>
                </InputLabel>
                <InputPassword
                  {...formik.getFieldProps('password')}
                  error={formik?.errors?.password}
                />
              </div>

              <ButtonIconMoon
                className={styles['btn-submit']}
                iconHoverName="icon-arrow-white-2"
                iconName="icon-arrow-white-1"
                iconPosition="end"
                iconSize="md"
                size="md"
                variant="primary-light"
                type={'submit'}
                disabled={formik.isSubmitting || mutation.isLoading}
              >
                <Typography variant={'body-1'} typographyColor="white">
                  {t('auth:form.submitButton.label')}
                </Typography>{' '}
              </ButtonIconMoon>
            </form>

            <Spacer variant={'vertical'} size={'5x'} />

            <div className={styles['forgot-password']}>
              <Typography variant={'body-1'} tag={'span'}>
                {t('auth:forgotPassword')}
              </Typography>
              <Anchor href={'/reset-password'}>
                {t('auth:recoverPassword')}
              </Anchor>
            </div>
          </div>
          <div className={styles['footer-card']}>
            <div>
              <Typography variant={'body-1'} typographyColor="gray-4">
                {t('auth:haveAccount')}{' '}
              </Typography>{' '}
              <Anchor href={'/register-account'}>
                {t('auth:authSection.registerLink')}
              </Anchor>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default LoginFormPage
