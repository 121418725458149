import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useNonNullableParams } from 'src/utils'
import styles from './register.module.scss'
import { HeaderAuth } from 'src/components'
import { Typography } from '@prometeoapi/afrodita-react-component-lib'
import {
  RegisterAccountConfirmPayload,
  RegisterAccountConfirmResponse,
  useAccountConfirmService,
} from 'src/infrastructure'

export const RegisterAccounConfirmPage = () => {
  const { t } = useTranslation(['register'])
  const navigate = useNavigate()
  const [data, setData] = useState<RegisterAccountConfirmResponse>({
    message: '',
    success: false,
  })
  const [loading, setLoading] = useState(false)
  const { uuid } = useNonNullableParams('uuid')
  const { token } = useNonNullableParams('token')
  const { accountConfirm } = useAccountConfirmService()

  useEffect(() => {
    setLoading(true)
    const payload: RegisterAccountConfirmPayload = {
      uuid,
      token,
    }

    const fetchData = async () => {
      const response = await accountConfirm(payload)
      setData(response.data)
      if (response.data.success) {
        navigate('/login')
      }
      setLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <HeaderAuth />

      <section className={styles['page-container']}>
        {loading ? (
          <div className={styles['info-section']}>
            <Typography variant="featured-3" withFontFamily>
              {t(`register:registerPage.activeLoading`)}
            </Typography>
          </div>
        ) : (
          <div className={styles['info-section']}>
            <Typography
              variant="featured-3"
              withFontFamily
              typographyColor="red-5"
            >
              {data?.message}
            </Typography>
          </div>
        )}
      </section>
    </div>
  )
}
