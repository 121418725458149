import { useTranslation } from 'react-i18next'
import {
  Typography,
  Button,
  InputLabel,
  Anchor,
  Alert,
  AlertBody,
} from '@prometeoapi/afrodita-react-component-lib'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import styles from './reset-password.module.scss'
import { useResetPasswordUseCases } from 'src/service-locator'
import { useState } from 'react'
import { HeaderAuth, InputText } from 'src/components'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
interface IFormInput {
  email: string
}

export const ResetPasswordPage = () => {
  const { t } = useTranslation(['shared', 'resetpage'])
  const [errorMessage, setErrorMessage] = useState('')
  const { resetPassword } = useResetPasswordUseCases()
  const [emailError, setEmailError] = useState<string | null>(null)
  const [success, setSuccess] = useState(false)

  const formSchema = Yup.object({
    email: Yup.string()
      .email(
        t('shared:form.errors.emailField', {
          name: t(`register:registerPage.emailInput`),
        })
      )
      .required(
        t('shared:form.errors.requiredField', {
          name: t(`register:registerPage.emailInput`),
        })
      ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(formSchema),
  })

  const resetPasswordAction = async (data: any) => {
    try {
      await resetPassword(data)
      setSuccess(true)
      reset()
    } catch (error: any) {
      if (errors) {
        setEmailError(error.response.data?.email || null)
      }
      setErrorMessage(error._messageForUser.description)
      return error
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    resetPasswordAction(data)
  }

  return (
    <div>
      <HeaderAuth />

      <section className={styles['page-container']}>
        <div
          className={success === true ? styles['row-success'] : styles['row']}
        >
          <div className={styles['body-card']}>
            {success && (
              <div>
                <Alert className="alert" variant="success">
                  <AlertBody>
                    <Typography
                      variant="body-1"
                      withFontFamily
                      typographyColor="green-7"
                    >
                      {t(`resetpage:resetPage.successMessage`)}
                    </Typography>
                  </AlertBody>
                </Alert>
              </div>
            )}
            <Typography variant="display-3" withFontFamily>
              {t(`resetpage:resetPage.title`)}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <div className={styles['input input-group']}>
                    <InputLabel htmlFor="some-input-id" id="input-label">
                      <Typography variant={'body-2'} typographyColor="gray-6">
                        {t('resetpage:resetPage.emailInputh')}
                      </Typography>
                    </InputLabel>
                    <InputText
                      {...field}
                      type="email"
                      error={emailError || errors?.email?.message}
                    />
                  </div>
                )}
              />
              {errorMessage ? (
                <p className={styles['error-text']}>{errorMessage} </p>
              ) : (
                ''
              )}
              <div className={styles['button-container']}>
                <Button type={'submit'} fullWidth>
                  {t(`resetpage:resetPage.submit`)}{' '}
                </Button>
              </div>
            </form>
          </div>
          <div className={styles['footer-card']}>
            <div>
              <Typography variant={'body-1'} typographyColor="gray-4">
                {t('auth:backLogin')}
              </Typography>{' '}
              <Anchor href={'/'}>{t('auth:logIn')}</Anchor>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
