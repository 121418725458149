export default {
  nav: {
    useCases: 'Use cases',
    security: 'Security',
    documentation: 'Documentation',
    glossary: 'Glossary',
    resources: 'Resources',
    reports: 'Reports',
    aboutPrometeo: 'About Prometeo',
    aboutUs: 'About Us',
    workWithUs: 'Work with Us',
    faq: 'FAQs',
    helpCenter: 'Help Center',
    products: 'Products',
    team: 'Our Team',
    privacyPolicy: 'Privacy Policy',
    securityPolicy: 'Security Policy',
    press: 'Press',
    medium: 'Medium',
    start: 'Start the integration',
    paymentApi: 'Bank transfer payments',
    paymentsQR: 'QR payments',
    paymentslink: 'Payment link',
    dataApi: 'Cash management',
    validationApi: 'Bank account validation',
    ourBankingApis: 'Our Banking APIs',
    informationApis: 'Information APIs',
    paymentApis: 'Payment APIs',
    validationApis: 'Validation APIs',
    ourUseCases: 'Our Use Cases',
    accountToAccountPayments: 'Account to Account Payments',
    treasuryManagement: 'Treasury Management',
    bankInfoConsolidation: 'Banking Information Consolidation',
    bankInfoAccess: 'Banking Information Access',
    bankAccountValidation: 'Bank Account Authentication',
    bank: 'Banks',
    credits: 'Credits and loans',
    ecommerce: 'E-commerce and marketplace',
    paymentGateway: 'Payment gateway',
    services: 'Services',
    partnersProgram: 'Prometeo Partners Program',
    useCasesNav: {
      ourUseCases: 'Our Use Cases',
      useCasesDescription:
        'By connecting to our APIs you will be able to automate, digitize and access banking information relevant to your company.',
    },
    product: {
      ourProducts: 'Our Products',
      ourProductsDescription:
        'With our APIs we allow companies to interoperate with banks in the region.',
    },
  },
  form: {
    fields: {
      email: { placeholder: 'Email' },
      password: { placeholder: 'Password' },
    },
    submitButton: {
      label: 'Login',
    },
  },
  langEs: 'Spanish',
  langEn: 'English',
  logIn: 'Log In',
  renewCredential: 'Update Password',
  buttonUpdtae: 'Update',
  successUpdateExpiredPassword: 'Your password was updated successfully ',
  alert:
    'Your password has expired, you need to update it to gain access again',
  haveAccount: 'Do you have an account? ',
  backLogin: 'Back to ',
  signUp: 'Register',
  forgotPassword: 'Did you forget your password? ',
  recoverPassword: 'Recover your password',
  authSection: {
    loginQuestion: 'Already have an account? ',
    registerQuestion: 'You do not have an account?',
    loginLink: 'Login',
    registerLink: 'Sign up',
  },
  error: {
    length: 'Password must be at least 8 char long',
    lengthIdExterno: 'The external ID must not exceed 22 characters',
    regex:
      'Your password must Contain Characters in Uppercase, Lowercase, Number and One Special Case Character',
    match: 'Passwords does not match',
    generic: 'Could not change password, please check your email',
  },
}
