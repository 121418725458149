export default {
  resetPage: {
    title: 'Restore password',
    submit: 'Restore',
    emailInputh: 'Email',
    loginQuestion: 'Already have an account?',
    registerQuestion: 'You do not have an account?',
    loginLink: 'Login',
    registerLink: 'Sign up',
    successMessage:
      'If your account is registered with the email address you entered, you will soon receive instructions on how to reset your password.“',
  },
  resetPassworEmail: {
    greetings: 'Hello, ',
    message:
      ' We received your request to reset your password, please click the button to choose a new one',
    choosePassword: 'Choose a new password',
    errorButton: 'If the button does not work, click on this link: ',
  },
  resetPasswordConfirm: {
    newPassword: 'New Password',
    confirmPassword: 'Confirm your new password',
    errorMessage: 'Password change failed',
    form: {
      fields: {
        newPassword: {
          label: 'New Password',
        },
        confirmPassword: {
          label: 'Confirm New Password',
        },
      },
    },
    error: {
      length: 'Password must be at least 8 char long',
      regex:
        'Your password must Contain Characters in Uppercase, Lowercase, Number and One Special Case Character',
      match: 'Passwords does not match',
      generic: 'Could not change password, please check your email',
    },
  },
}
