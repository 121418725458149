import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { ReactQueryClientProvider, AuthProvider } from 'src/presentation'

import bootstrap from './bootstrap'
import App from './App'

import './prometeo-components-library-react/src/assets/css/prometeo-tokens.css'
import './prometeo-components-library-react/src/assets/css/prometeo-fonts.css'
import './prometeo-components-library-react/src/assets/css/prometeo-utility-classes.css'
import './prometeo-components-library-react/src/assets/css/prometeo-normalize.css'

import '@prometeoapi/afrodita-react-component-lib/build/styles/css/prometeo-tokens.css'
import '@prometeoapi/afrodita-react-component-lib/build/styles/css/prometeo-fonts.css'
import '@prometeoapi/afrodita-react-component-lib/build/styles/css/prometeo-utility-classes.css'
import '@prometeoapi/afrodita-react-component-lib/build/styles/css/prometeo-normalize.css'
import './index.css'
import './locales/i18n'

bootstrap()

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_PUBLIC_GA_GTM_ID ?? 'GTM-000000',
}

TagManager.initialize(tagManagerArgs)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ReactQueryClientProvider>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </ReactQueryClientProvider>
  </React.StrictMode>
)
